<script>

  import { ServerValidationError } from '@/utils/errors'
  import Enums from '@/utils/enums'
  import ErrorMessages from '@/utils/errors/messages'

  export default {
    data() {
      return {
        errors: []
      }
    },
    methods: {
      handleError(error) {
        //console.log(error)
        if (error instanceof ServerValidationError) {
          this.errors = [...data.errorList]
          return
        }
        var errorMessage = ErrorMessages.getErrorMessageByErrorCode(error.appResultCode, Enums.RequestType.Vacation.value)
        //console.log(errorMessage)
        this.showErrorMessage(errorMessage)
      }
    }
  };</script>
