const ErrorMessages = {
  GeneralError: {
    code: 0,
    message: {
      default: 'An unexpected error has occured. Kindly contact the system administrator.'
    }
  },
  UnAuthorized: {
    code: 40101,
    message: {
      default: 'You are not authorized to perform this action'
    }
  },
  CannotRaiseRequestDueToEmployementDate: {
    code: 200,
    message: {
      default: 'You are unable to raise this request due to your employment date',
      personalBusinessDay: 'You myst have worked for a minimum of 91 days to be entitled for personal business days'
    }
  },
  CannotRaiseRequestDueToMinimumStartDateNotCurrentYear: {
    code: 200,
    message: {
      default: 'You are unable to raise this request because the minimum start date does not fall into the current year',
    }
  },
  InvalidEmployeeType: {
    code: 40001,
    message: {
      default: 'Only regular and expatriate employees are allowed for this requests',
      StudyLeave: 'Only regular employees are allowed to raise study leave requests',
      fieldVacationOption: 'Only regular field employees are allowed to raise field vacation option',
      CashInLieu: 'Only regular field employees on 14*14 workschedule are allowed to raise a cash in lieu of vacation request',
      Vacation: 'Only regular and resident expatriate employees are allowed to raise vacation requests',
      RnR: 'Only regular and expatriate employees are allowed to raise R&R requests'
    }
  },
  CannotRaiseRequestDueToEmployeeTypeOrScheduleOrLocation: {
    code: 200,
    message: {
      default: 'You are unable to schedule this request due to your current work schedule.',
      personalBusinessDay: 'Only regular employees in office locations on 5/2 work schedule are entitled to personal business days',
      CashInLieuOfVacation: 'Only regular field employees can manually request for cash in lieu of vacation',
      BuyBack: 'Vacation buy back requests can only be raised for office based employees. Field employees can request for cash in lieu of vacation'
    }
  },
  EmployeeOnForeignService: {
    code: 200,
    message: {
      default: 'employees on foreign service are not allowed to raise this leave requests',
      StudyLeave: 'employees on foreign assignment or on a company sponsored educational program, are required to complete their assignments, return to Nigeria and work for a minimum period of twelve months before they can obtain study leave'
    }
  },
  CannotRaiseRequestDueToEmployeeGender: {
    code: 200,
    message: {
      default: 'Invalid gender for leave request'
    }
  },
  CannotRaiseRequestForSelf: {
    code: 200,
    message: {
      default: 'Invalid Employee Entry. You cannot raise this Request for yourself ',
      BuyBack: 'You cannot raise a vacation buy back request for yourself'
    }
  },
  CannotRaiseRequestDueToPreAnniversary: {
    code: 200,
    message: {
      default: 'You cannot raise this request for an employee with less than one year of service'
    }
  },
  NumberOfYearsOfContinousEmployment: {
    code: 200,
    message: {
      default: 'You cannot raise this leave request due to your number of years of continous employment',
      studyLeave: 'You cannot raise a study leave request with less than 5 years of continous employment',
      leaveOfAbsence: 'You cannot raise a leave of absence request with less than a year of continous employment'
    }
  },
  CannotRaiseRequestDueToLeaveStartDateLessThanAYearAfterPreviousRequests: {
    code: 200,
    message: {
      default: 'Leave start date must be at leavt a year after ypur resumption from prior study leave / leave of absence'
      //studyLeave: 'You cannot raise a study leave request with less than 5 years of continous employment'
    }
  },
  CannotScheduleRequestDueToClosedWindow: {
    code: 200,
    message: {
      default: 'Request cannot be sxheduled because window for such schedule is closed'
      //studyLeave: 'You cannot raise a study leave request with less than 5 years of continous employment'
    }
  },
  CannotRaiseRequestDueToNoFieldVacationScheduleOption: {
    code: 200,
    message: {
      default: 'Vacation entitlement data not found. Field Employee must indicate their Vacation Schedule Option first. Kindly click the button below to continue.'
      //studyLeave: 'You cannot raise a study leave request with less than 5 years of continous employment'
    }
  },
  CannotRaiseRequestDueToWorkSchedule28OrCashInLieuFieldVacationOption: {
    code: 200,
    message: {
      default: 'Scheduling not allowed for field employees who already chosen Cash In-lieu or work 28/28. Kindly contact HRIT for assistance'
    }
  },
  RunningLeaveOfAbsenceOrStudyLeave: {
    code: 200,
    message: {
      default: 'Scheduling not allowed because you have a current study leave or leave of absence that has not been closed out.'
    }
  },
  CannotRaiseRequestBecauseYearlyTotalMet: {
    code: 200,
    message: {
      default: 'You have scheduled the total number of Leave Days for the year'
    }
  },
  ApprovedRequestCannotBeEdited: {
    code: 200,
    message: {
      default: 'An already approved request cannot be modified'
    }
  },
  CannotEditRequest: {
    code: 200,
    message: {
      default: 'you are either not authorized to edit this request or you cannot modify the request in it\'s current state'
    }
  },
  RequestCannotBeModifiedDueToCurrentState: {
    code: 200,
    message: {
      default: 'The request cannot be modified in it\'s current state'
    }
  },
  EmployeesOn28DaysScheduleCannotScheduleVacation: {
    code: 200,
    message: {
      default: 'Employees on 28x28 work schedule cannot schedule vacations and are paid cash in lieu'
    }
  },
  EmployeesOn28DaysScheduleCannotRequestCashInLieu: {
    code: 200,
    message: {
      default: 'Employees on 28x28 work schedule cannot manually request for cash in lieu of vacation.'
    }
  },
  MaximumNumberOfVacationForFieldEmployees: {
    code: 200,
    message: {
      default: 'Field employees are only allowed one vacation in a year'
    }
  },
  RequestCannotBeRaisedDueToUnscheduledVacationDays: {
    code: 200,
    message: {
      default: 'Employee must have exhausted annual vacation before compassionate leave can be scheduled'
    }
  },
  VacationMasterNotYetGenerated: {
    code: 200,
    message: {
      default: 'Vacation entitlement data not found. Kindly click the button below to compute your Vacation Days Entitlement',
      VacationDaysAdjustment: 'Vacation entitlement data not found. Kindly generate data for employee before adjusting vacation days'
    }
  },
  VacationMasterAlreadyExistsForCashInLieu: {
    code: 200,
    message: {
      default: 'Vacation data has already been generated for this employee'
    }
  },
  ValidCashInLieuRequestAlreadyExists: {
    code: 200,
    message: {
      default: 'a cash in lieu request has already been made for the employee and can no loner request another',
      Vacation: 'A cash in lieu of vacation request already exists for this employee and can no longer make vacation requests'
    }
  },
  VacationCannotBeEditedForPreviousYears: {
    code: 200,
    message: {
      default: 'vacation data cannot be edited for a prior year'
    }
  },
  NoVacationRecordForPreviousYear: {
    code: 200,
    message: {
      default: 'vacation for previous year not found'
    }
  },
  NoUnscheduledVacationDays: {
    code: 200,
    message: {
      default: 'The employee has exhausted his/her leave days'
    }
  },
  CarriedOverDaysCanOnlyBeUsedOnOrBeforeLastMonthForVacationCarryOver: {
    code: 200,
    message: {
      default: 'Carried over days cannot be used after march'
    }
  },
  MissingSBUStartDate: {
    code: 200,
    message: {
      default: 'NMA start date is not defined for employee. Kindly contact HR Expatriates admin'
    }
  },
  RequiredMinimumSBUStay: {
    code: 200,
    message: {
      default: 'RequiredMinimumSBUStay'
    }
  },
  MaximumNumberOfRequestsInYear: {
    code: 200,
    message: {
      default: 'MaximumNumberOfRequestsInYear'
    }
  },
  //NotYetFirstMonthForVacationCarryOver: {
  //  code: 200,
  //  message: {
  //    default: '  Carry Over utilization period has elapsed for the current vacation year'
  //  }
  //},
  LastMonthForVacationCarryOverElapsed: {
    code: 200,
    message: {
      default: 'Carry Over utilization period has elapsed for the current vacation year'
    }
  },
  CarryOverDaysAlreadyExist: {
    code: 200,
    message: {
      default: 'Carry over days already exist for this vacatio  year'
    }
  },
  CarryOverRequestAlreadyExist: {
    code: 200,
    message: {
      default: 'An existing carry over request already exist for this vacatio  year'
    }
  },
  FieldEmployeesNotAllowed: {
    code: 200,
    message: {
      default: 'Field employees are not allowed to raise this request'
    }
  },
  NoUSDAddressBookNUmberForRnR: {
    code: 200,
    message: {
      default: 'USD Address book number not yet setup for employee. Kindly contact Expatriate admin to get this resolved'
    }
  },
  getByErrorType(error, scope = 'default') {
    if (error) {
      return error.message[scope] || error.message.default
    }
    else {
      return 'An unknown error occured'
    }
  },
  getErrorMessageByErrorCode(code, scope = 'default') {
    if (code) {
      for (const error in ErrorMessages) {
        if (ErrorMessages[error].code === code) {
          //alert(ErrorMessages[error].message[scope] || ErrorMessages[error].message.default)
          return ErrorMessages[error].message[scope] || ErrorMessages[error].message.default
        }
      }
    }
    return 'An unknown error occured'
  }
}

export default ErrorMessages
