<script>

  import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS } from '@/utils/constants'
  import ELeaveError from '@/utils/errors/eLeaveError'

  export default {
    data() {
      return {
        alert: {
          message: '',
          type: ALERT_TYPE_ERROR
        }
      }
    },
    methods: {
      showSuccessMessage(message) {
        this.alert.message = message
        this.alert.type = ALERT_TYPE_SUCCESS
      },
      showErrorMessage(error) {
        console.log(error)
        let errorMessage = error instanceof Error ? error.message : error
        this.alert.message = errorMessage
        this.alert.type = ALERT_TYPE_ERROR
      },
      closeMessage() {
        this.alert.message = ''
        this.alert.isActive = false
      }
    }
  };</script>
