import { format } from 'date-fns'

export const toLongDateString = (date) => {
  if (date) {
    const formatted = format(new Date(date), 'dd/MMM/yyyy')
    return formatted
  }
  return ''
}

export const toShortDateString = (date) => {
  if (date) {
    return format(new Date(date), 'dd/MMM/yyyy')
  }
  return ''
}

export const toShortDateTimeString = (date) => {
  if (date) {
    return format(new Date(date), 'dd/MMM/yyyy hh:mm aaa')
  }
  return ''
}

export const toStatesList = (states) => {
  if (states.length) {
    return states.map(state => state.description).join(', ')
  }
  else {
    return 'All'
  }
}

export const toNumericDisplay = (value) => {
  return value.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
}
